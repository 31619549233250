import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
})
export default class FocusModeMixin extends Vue {
  mounted () {
    this.$store.commit('setNavMenuHide', true);
  }

  setBreadcrumb ({ indexTitle, editTitle, indexRouteName }) {
    this.$store.commit('setBreadcrumbItems', [
      {
        name: this.$store.getters['entities/Location'](this.locationId).title,
      },
      {
        name: indexTitle,
        routerLink: { name: indexRouteName, params: { locationId: this.locationId } },
      },
      {
        name: editTitle,
      },
    ]);
  }

  beforeDestroy () {
    this.$store.commit('setNavMenuHide', false);
  }
}
