<template>
  <ValidationProvider tag="div" :rules="rules" v-slot="{ errors }" :vid="vid" :name="!fieldNameOverride ? title : fieldNameOverride">
    <UiFormItem :title="title" :instructions="instructions" :required="required" :error-msg="errors[0]" :error="!!errors[0]" :root="true">
      <input class="ui-input input-width-hack" v-if="!textarea" v-bind="$attrs" :type="type || 'text'" v-on="$listeners" v-model="internalValue" :class="{'-error': !!errors[0]}" />
      <textarea class="ui-textarea input-width-hack" v-else autosize="off" v-bind="$attrs" v-on="$listeners" v-model="internalValue" :style="{minHeight: height}" :class="{'-error': !!errors[0], '-minheight': defaultMinHeight}"></textarea>
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>

    </UiFormItem>
  </ValidationProvider>
</template>

<script>
import Vue from 'vue';
import ModelMixin from './ModelMixin';
import Component from 'vue-class-component';
import UiFormItem from './UIFormItem';
import { ValidationProvider } from 'vee-validate';

@Component({
  name: 'UIInput',
  mixins: [
    ModelMixin,
  ],
  inheritAttrs: false,
  props: {
    ...UiFormItem.options.props,
    textarea: Boolean,
    height: String,
    defaultMinHeight: Boolean,
    value: {
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    fieldNameOverride: {
      type: String,
    },
    vid: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  components: {
    UiFormItem,
    ValidationProvider,
  },
  data: () => ({
    currentValue: '',
  }),
  watch: {
    currentValue (val) {
      // allows us to use v-model on our input.
      this.$emit('input', val);
    },
  },
})
export default class UIInput extends Vue {

}
</script>

<style scoped lang="postcss">
@import "../../styles";

.ui-input,
.ui-textarea {
  &.-error {
    border-color: var(--colorErrorFlat);
    box-shadow: none;
    border-width: 2px;
  }
}
.ui-input {
  @apply --text;
  cursor: text;
  display: block;
}
.ui-textarea {
  @apply --text;
  resize: none;
  cursor: text;
  &.-minheight {
    min-height: 15rem;
  }
}

.input-width-hack {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}
</style>
